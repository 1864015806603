import { EditOutlined, RemoveRedEye } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { API_URL } from "../config/Constant";
import { handleSnack } from "../store/snack";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
export const XPathEdit = (props: { [key: string]: any }) => {
    const { state } = useLocation();
    const baseXPath = state?.xPath || props.xPath || {}
    const [xPath, setXPath] = React.useState(baseXPath);
    const dispatch = useDispatch();
    function handleChange(e: any) {
        const newXPath = { ...xPath };
        newXPath[e.target.name] = e.target.value;
        setXPath(newXPath);
    };

    function deleteXPath() {
        var url = API_URL + "/api/v1/xpath/" + xPath.id
        Http.delete(url, {}, (response: any) => {
            handleSnack(dispatch, 'XPath Deleted');
            props.onClose?.();
        });
    };

    function addUpdateXPath() {
        var url = API_URL + "/api/v1/xpath"
        Http.put(url, xPath, (response: any) => {
            handleSnack(dispatch, 'XPath Synchronized');
            props.onClose?.();
        });
    };
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <TextField
                sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                required
                name='name'
                label="Name"
                defaultValue={xPath.name}
                onChange={handleChange}
            />
            <TextField
                sx={{ flexGrow: 1, flex: '1 1 45%', marginTop: '10px' }}
                required
                name='key'
                label="Key"
                defaultValue={xPath.key}
                onChange={handleChange}
            />
            <TextField
                sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                required
                name='type'
                label="Type"
                defaultValue={xPath.type}
                onChange={handleChange}
            />
            <TextField
                sx={{ flexGrow: 1, flex: '1 1 45%', marginTop: '10px' }}
                required
                name='xpath'
                label="X Path"
                defaultValue={xPath.xpath}
                onChange={handleChange}
            />
            <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                {!Validator.isEmpty(xPath.id) && <Button sx={{ flex: '1 1 45%', marginRight: '6px' }} variant="contained" onClick={deleteXPath}>Delete</Button>}
                <Button sx={{ flex: '1 1 45%' }} variant="contained" onClick={addUpdateXPath}>{!Validator.isEmpty(xPath.id) ? 'Update' : 'Add'}</Button>
            </Box>
        </Box>
    )
};