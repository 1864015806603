import { Add, EditOutlined, Remove, RemoveRedEye } from "@mui/icons-material";
import { Autocomplete, Button, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { API_URL, BASE_CONFIG, BASE_STEP, SUPPORTED_OPERATORS } from "../config/Constant";
import { AutomationConfig, AutomationStep, XPath } from "../store/entity";
import { handleSnack } from "../store/snack";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
export const TestSuiteEdit = (props: { [key: string]: any }) => {
    const { state } = useLocation();
    var baseSuite = state?.suite || props.suite || BASE_CONFIG;    
    const [suite, setSuite] = React.useState(baseSuite);
    const dispatch = useDispatch();
    function handleChange(e: any) {
        const newSuite = { ...suite };
        newSuite[e.target.name] = e.target.value;
        setSuite(newSuite);
    };

    function deleteSuite() {
        var url = API_URL + "/api/v1/suite/" + suite.id
        Http.delete(url, {}, (response: any) => {
            handleSnack(dispatch, 'Suite Deleted');
            props.onClose?.();
        });
    };

    function addUpdateSuite() {
        var url = API_URL + "/api/v1/suite"
        Http.put(url, suite, (response: any) => {
            handleSnack(dispatch, 'Suite Synchronized');
            props.onClose?.();
        });
    };

    function updateConfigs(newConfigs: AutomationConfig[]) {
        const newSuite = { ...suite };
        newSuite.configs = newConfigs;
        setSuite(newSuite);
    };
    const [iConfigs, setIConfigs] = React.useState<AutomationConfig[]>([]);
    const [text, setText] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (!Validator.isEmpty(text)) {
            var url = API_URL + "/api/v1/config/filter";
            var params = {
                text: text,
                exclude: -1
            }
            Http.get(url, params, (response: any) => {
                setIConfigs(response.data.configs);
            });
        }
    }, [text]);    

    
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', maxHeight: '100%', overflow: 'scroll' }}>
            <TextField
                sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                required
                name='name'
                label="Name"
                defaultValue={suite.name}
                onChange={handleChange}
            />                        
            <Box sx={{ marginTop: '10px', width: '100%' }}>
                <Autocomplete
                    multiple
                    options={iConfigs}
                    getOptionLabel={(option: AutomationConfig) => option.name}
                    style={{ width: '100%' }}
                    isOptionEqualToValue={(option: AutomationConfig, value: AutomationConfig) => option.id === value.id}
                    onChange={(event: any, newValue: AutomationConfig[]) => {
                        updateConfigs(newValue);
                    }}
                    value={suite.configs}
                    onInputChange={(event: any, newText: string) => {
                        setText(newText);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Configs" variant="outlined" />
                    )}
                />
            </Box>            
            <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                {!Validator.isEmpty(suite.id) && <Button sx={{ flex: '1 1 45%', marginRight: '6px' }} variant="contained" onClick={deleteSuite}>Delete</Button>}
                <Button sx={{ flex: '1 1 45%' }} variant="contained" onClick={addUpdateSuite}>{!Validator.isEmpty(suite.id) ? 'Update' : 'Add'}</Button>
            </Box>
        </Box>
    )
};