import { AppBar, IconButton, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "../scss/base.scss";
import { useDispatch, useSelector } from "react-redux";
import { drawlerSelector } from "../store/selectors";
import { handlerDrawler } from "../store/drawer";
import { SideDrawer } from "./SideDrawer";
import { Snack } from "./Snack";
const Header = (props: { [key: string]: any }) => {
    const drawer: boolean = useSelector(drawlerSelector);
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <title>LevelAI Automation</title>
            <link href="/images/logo.svg" rel="shortcut icon" type="image/svg" />
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            sx={{ mr: 2 }}
                            onClick={() => handlerDrawler(dispatch, !drawer)}>
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            <SideDrawer/>
            <Snack/>
        </React.Fragment>
    );
};

export default Header;
