import { Add, EditOutlined, Remove, RemoveRedEye } from "@mui/icons-material";
import { Autocomplete, Button, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { API_URL, BASE_STEP, SUPPORTED_OPERATORS } from "../config/Constant";
import { AutomationStep, AutomationStepStep, XPath } from "../store/entity";
import { handleSnack } from "../store/snack";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
export const TestStepEdit = (props: { [key: string]: any }) => {
    const { state } = useLocation();
    var baseStep = state?.step || props.step || BASE_STEP;        
    const [step, setStep] = React.useState<AutomationStep>(baseStep);
    const dispatch = useDispatch();
    function handleChange(key: 'name' | 'operation', value: string) {
        const newStep = { ...step };
        newStep[key] =value;
        setStep(newStep);
    };

    function deleteStep() {
        var url = API_URL + "/api/v1/step/" + step.id
        Http.delete(url, {}, (response: any) => {
            handleSnack(dispatch, 'Step Deleted');
            props.onClose?.();
        });
    };

    function addUpdateStep() {
        var url = API_URL + "/api/v1/step"
        Http.put(url, step, (response: any) => {
            handleSnack(dispatch, 'Step Synchronized');
            props.onClose?.();
        });
    };

    function updateInternalSteps(newSteps: AutomationStep[]) {
        const newStep = { ...step };        
        const newStepsSteps: AutomationStepStep[] = [];
        newSteps.forEach((newStep, i) => {
            const newStepsStep = {
                step_index: i,
                step: newStep,
                parent_step: {
                    id: step.id
                }
            };
            newStepsSteps.push(newStepsStep);
        });
        newStep.internal_steps = newStepsSteps;
        setStep(newStep);
    };
    const [iSteps, setISteps] = React.useState<AutomationStep[]>([]);
    const [text, setText] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (!Validator.isEmpty(text)) {
            var url = API_URL + "/api/v1/step/filter";
            var params = {
                text: text,
                exclude: step.id || -1
            }
            Http.get(url, params, (response: any) => {
                setISteps(response.data.steps);
            });
        }
    }, [text]);

    function updateXPaths(newXPaths: XPath[]) {
        const newStep = { ...step };
        newStep.xpaths = newXPaths;
        setStep(newStep);
    }
    const [iXPaths, setIXPaths] = React.useState<XPath[]>([]);
    const [xPathText, setXPathText] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (!Validator.isEmpty(xPathText)) {
            var url = API_URL + "/api/v1/xpath/filter";
            var params = {
                text: xPathText,
                exclude: step.id
            }
            Http.get(url, params, (response: any) => {
                setIXPaths(response.data.xpaths);
            });
        }
    }, [xPathText]);

    const [newParam, setNewParam] = React.useState<{ [key: string]: any } | null>(null);
    function handleNewParam(key: string, value: string) {
        var updatedNewParams = { ...newParam };
        updatedNewParams[key] = value;
        setNewParam(updatedNewParams);
    };

    function addNewParam() {
        var newStep = { ...step };
        newStep.params[newParam?.key] = newParam?.value;
        setNewParam(null);
    };

    function removeParam(key: string) {
        var newStep = { ...step };
        delete newStep.params[key];
        setStep(newStep);
    };
    function updateParam(key: string,  value: string) {
        var newStep = { ...step };
        newStep.params[key] = value;        
        setStep(newStep);
    };
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', maxHeight: '100%', overflow: 'scroll' }}>
            <TextField
                sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                required
                name='name'
                label="Name"
                defaultValue={step.name}
                onChange={(e: any) => handleChange('name', e.target.value)}
            />
            <TextField
                sx={{ flexGrow: 1, flex: '1 1 45%', marginTop: '10px' }}
                required
                select
                name='operation'
                label="Operation"
                defaultValue={step.operation}
                onChange={(e: any) => handleChange('operation', e.target.value)}>
                {SUPPORTED_OPERATORS.map((operator) => (
                    <MenuItem key={operator} value={operator}>
                        {operator}
                    </MenuItem>
                ))}
            </TextField>
            <Box sx={{ marginTop: '10px', width: '100%' }}>
                <Autocomplete
                    multiple
                    options={iXPaths}
                    getOptionLabel={(option: XPath) => option.name}
                    style={{ width: '100%' }}
                    isOptionEqualToValue={(option: XPath, value: XPath) => option.id === value.id}
                    onChange={(event: any, newValue: XPath[]) => {
                        updateXPaths(newValue);
                    }}
                    value={step.xpaths}
                    onInputChange={(event: any, newText: string) => {
                        setXPathText(newText);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="XPaths" variant="outlined" />
                    )}
                />
            </Box>
            <Box sx={{ marginTop: '10px', width: '100%' }}>
                <Autocomplete
                    multiple
                    options={iSteps}
                    getOptionLabel={(option: AutomationStep) => option.name}
                    style={{ width: '100%' }}
                    isOptionEqualToValue={(option: AutomationStep, value: AutomationStep) => option.id === value.id}
                    onChange={(event: any, newValue: AutomationStep[]) => {
                        updateInternalSteps(newValue);
                    }}
                    value={step.internal_steps.map(stepStep => stepStep.step)}
                    onInputChange={(event: any, newText: string) => {
                        setText(newText);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Internal Steps" variant="outlined" />
                    )}
                />
            </Box>
            <Box sx={{ marginTop: 5 }}>
                <Typography>Params</Typography>
                {Object.keys(step.params).map((paramKey: string) =>
                    <Box key={paramKey} sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                            required
                            label="Key"
                            defaultValue={paramKey}
                            disabled
                        />
                        <TextField
                            sx={{ flexGrow: 1, flex: '1 1 45%', marginTop: '10px' }}
                            required
                            label="Value"
                            defaultValue={step.params[paramKey]}
                            onChange={(e: any) => updateParam(paramKey, e.target.value)}
                        />
                        <Remove sx={{ cursor: 'pointer' }} onClick={() => removeParam(paramKey)} />
                    </Box>
                )}
                {!Validator.isEmpty(newParam) && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                        required
                        label="Key"
                        defaultValue={newParam?.key}
                        onChange={(e: any) => handleNewParam('key', e.target.value)}
                    />
                    <TextField
                        sx={{ flexGrow: 1, flex: '1 1 45%', marginTop: '10px' }}
                        required
                        label="Value"
                        defaultValue={newParam?.value}
                        onChange={(e: any) => handleNewParam('value', e.target.value)}
                    />
                    <Add sx={{ cursor: 'pointer' }} onClick={addNewParam} />
                </Box>}
                {Validator.isEmpty(newParam) && <Button onClick={() => setNewParam({ key: '', value: '' })}>Add Param</Button>}
            </Box>
            <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                {!Validator.isEmpty(step.id) && <Button sx={{ flex: '1 1 45%', marginRight: '6px' }} variant="contained" onClick={deleteStep}>Delete</Button>}
                <Button sx={{ flex: '1 1 45%' }} variant="contained" onClick={addUpdateStep}>{!Validator.isEmpty(step.id) ? 'Update' : 'Add'}</Button>
            </Box>
        </Box>
    )
};