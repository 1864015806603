import React from 'react';
import { Provider } from "react-redux";
import ReactDOM from 'react-dom/client';
import Landing from './components/Landing';
import store from './store/store';
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <Landing/>
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>
);
