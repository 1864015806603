import { Add, EditOutlined, Remove, RemoveRedEye } from "@mui/icons-material";
import { Autocomplete, Button, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { API_URL, BASE_CONFIG, BASE_STEP, SUPPORTED_OPERATORS } from "../config/Constant";
import { AutomationConfig, AutomationConfigStep, AutomationStep, XPath } from "../store/entity";
import { handleSnack } from "../store/snack";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
export const TestConfigEdit = (props: { [key: string]: any }) => {
    const { state } = useLocation();
    var baseConfig = state?.config || props.config || BASE_CONFIG;    
    const [config, setConfig] = React.useState<AutomationConfig>(baseConfig);
    const dispatch = useDispatch();
    function handleChange(key: 'name', value: string) {
        const newConfig = { ...config };            
        newConfig[key] = value;
        setConfig(newConfig);
    };

    function deleteConfig() {
        var url = API_URL + "/api/v1/config/" + config.id
        Http.delete(url, {}, (response: any) => {
            handleSnack(dispatch, 'Config Deleted');
            props.onClose?.();
        });
    };

    function addUpdateConfig() {
        var url = API_URL + "/api/v1/config"
        Http.put(url, config, (response: any) => {
            handleSnack(dispatch, 'Config Synchronized');
            props.onClose?.();
        });
    };

    function updateSteps(newSteps: AutomationStep[]) {
        const newConfig = { ...config }; 
        const newConfigSteps: AutomationConfigStep[] = [];
        newSteps.forEach((step, i) => {
            const newConfigStep = {
                step_index: i,
                step: step,
                config: {
                    id: config.id
                }
            };
            newConfigSteps.push(newConfigStep);
        });
        newConfig.steps = newConfigSteps;
        setConfig(newConfig);
    };
    const [iSteps, setISteps] = React.useState<AutomationStep[]>([]);
    const [text, setText] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (!Validator.isEmpty(text)) {
            var url = API_URL + "/api/v1/step/filter";
            var params = {
                text: text,
                exclude: -1
            }
            Http.get(url, params, (response: any) => {
                setISteps(response.data.steps);
            });
        }
    }, [text]);    

    const [newParam, setNewParam] = React.useState<{ [key: string]: any } | null>(null);
    function handleNewParam(key: string, value: string) {
        var updatedNewParams = { ...newParam };
        updatedNewParams[key] = value;
        setNewParam(updatedNewParams);
    };

    function addNewParam() {
        var newConfig = { ...config };
        newConfig.params[newParam?.key] = newParam?.value;
        setNewParam(null);
    };

    function removeParam(key: string) {
        var newConfig = { ...config };        
        delete newConfig.params.key;
        setConfig(newConfig);
    };
    function updateParam(key: string, value: string) {
        var newConfig = { ...config };        
        newConfig.params[key] = value;
        setConfig(newConfig);
    };
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', maxHeight: '100%', overflow: 'scroll' }}>
            <TextField
                sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                required
                name='name'
                label="Name"
                defaultValue={config.name}
                onChange={(e) => handleChange('name', e.target.value)}
            />                        
            <Box sx={{ marginTop: '10px', width: '100%' }}>
                <Autocomplete
                    multiple
                    options={iSteps}
                    getOptionLabel={(option: AutomationStep) => option.name}
                    style={{ width: '100%' }}
                    isOptionEqualToValue={(option: AutomationStep, value: AutomationStep) => option.id === value.id}
                    onChange={(event: any, newValue: AutomationStep[]) => {
                        updateSteps(newValue);
                    }}
                    value={config.steps.map(config_step => config_step.step)}
                    onInputChange={(event: any, newText: string) => {
                        setText(newText);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Steps" variant="outlined" />
                    )}
                />
            </Box>
            <Box sx={{ marginTop: 5 }}>
                <Typography>Params</Typography>
                {Object.keys(config.params).map((paramKey: string) =>
                    <Box key={paramKey} sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                            required
                            label="Key"
                            defaultValue={paramKey}
                            disabled
                        />
                        <TextField
                            sx={{ flexGrow: 1, flex: '1 1 45%', marginTop: '10px' }}
                            required
                            label="Value"
                            defaultValue={config.params[paramKey]}
                            onChange={(e: any) => updateParam(paramKey, e.target.value)}
                        />
                        <Remove sx={{ cursor: 'pointer' }} onClick={() => removeParam(paramKey)} />
                    </Box>
                )}
                {!Validator.isEmpty(newParam) && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        sx={{ flexGrow: 1, flex: '1 1 45%', marginRight: '5px', marginTop: '10px' }}
                        required
                        label="Key"
                        defaultValue={newParam?.key}
                        onChange={(e: any) => handleNewParam('key', e.target.value)}
                    />
                    <TextField
                        sx={{ flexGrow: 1, flex: '1 1 45%', marginTop: '10px' }}
                        required
                        label="Value"
                        defaultValue={newParam?.value}
                        onChange={(e: any) => handleNewParam('value', e.target.value)}
                    />
                    <Add sx={{ cursor: 'pointer' }} onClick={addNewParam} />
                </Box>}
                {Validator.isEmpty(newParam) && <Button onClick={() => setNewParam({ key: '', value: '' })}>Add Param</Button>}
            </Box>
            <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                {!Validator.isEmpty(config.id) && <Button sx={{ flex: '1 1 45%', marginRight: '6px' }} variant="contained" onClick={deleteConfig}>Delete</Button>}
                <Button sx={{ flex: '1 1 45%' }} variant="contained" onClick={addUpdateConfig}>{!Validator.isEmpty(config.id) ? 'Update' : 'Add'}</Button>
            </Box>
        </Box>
    )
};