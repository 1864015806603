import { Autocomplete, Modal, styled, TablePagination } from "@mui/material";
export const useStylePagination = () => {
    return styled(TablePagination)({
        '& .MuiTablePagination-selectLabel': {
            margin: 0
        },
        '& .MuiTablePagination-displayRows': {
            margin: 0
        }
    });
}



export const StyledModal = styled(Modal)(({ theme }) => ({
    position: 'absolute',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',   
    maxWidth: 400,  
    maxHeight: 700,
    height: '50%',
    overflow: 'scroll-y',  
    p: 4,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    padding: '10px',
    outline: 'none',
    '& .MuiBackdrop-root': {
        backgroundColor: 'white'
    }    
}));
