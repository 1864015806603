import { Circle, EditOutlined, RemoveRedEye, Stop } from "@mui/icons-material";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, getSelectUtilityClasses, TableBody, Snackbar, Alert } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config/Constant";
import { AutomationBatch, AutomationStep, AutomationSuite, Column } from "../store/entity";
import { snackSelector } from "../store/selectors";
import { handleSnack, SnackState } from "../store/snack";
import { useStylePagination } from "../store/style";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
export const Snack = (props: { [key: string]: any }) => {
    const snack: SnackState = useSelector(snackSelector);
    const dispatch = useDispatch();        
return (
    <Snackbar open={!Validator.isEmpty(snack.message)} autoHideDuration={snack.duration} onClose={() => handleSnack(dispatch)}>
        <Alert onClick={() => handleSnack(dispatch)} severity={snack.severity} sx={{width: '100%'}}>{snack.message}</Alert>
    </Snackbar>
)
};