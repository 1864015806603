import { Add, EditOutlined, RemoveRedEye } from "@mui/icons-material";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, getSelectUtilityClasses, TableBody, Box, Button } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, BASE_STEP } from "../config/Constant";
import { AutomationStep, AutomationSuite, Column } from "../store/entity";
import { StyledModal, useStylePagination } from "../store/style";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
import { TestStepEdit } from "./TestStepEdit";
export const TestSteps = (props: { [key: string]: any }) => {
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [page_size, setPageSize] = React.useState(10);
    const [max, setMax] = React.useState(0);
    const [steps, setSteps] = React.useState<AutomationStep[]>([]);
    const StyleledTablePagination = useStylePagination();

    function updatePage(e: any, newPage: number) {
        setPage(newPage);
    };

    function updateRowsPerPage(event: any) {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columns: Column[] = [
        {
            name: 'Id',
            key: 'id',
            transformer: (step: AutomationStep) => step.id
        },
        {
            name: 'Name',
            key: 'name',
            transformer: (step: AutomationStep) => step.name
        },  
        {
            name: 'Operation',
            key: 'operation',
            transformer: (step: AutomationStep) => step.operation
        },      
        {
            name: 'XPaths',
            key: 'xpaths',
            transformer: (step: AutomationStep) => step.xpaths.length
        },        
        {
            name: 'Internal Steps',
            key: 'inter_operations_count',
            transformer: (step: AutomationStep) => step.internal_steps.length
        },
        {
            name: 'Params',
            key: 'params',
            transformer: (step: AutomationStep) => Object.keys(step.params).map((key: string) => <div>{key}: {step.params[key]}</div>)
        }
    ];

    function getSteps() {
        var url = API_URL + "/api/v1/step";
        var params = {
            page: page,
            page_size: page_size
        }
        Http.get(url, params, (response: any) => {
            setSteps(response.data.steps);
            setMax(response.data.count);
        });
    };

    React.useEffect(() => {
        getSteps();
    }, [page, page_size]);

    const [edit, setEdit] = React.useState<{[key: string]: any} | null>(null);

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell><Button onClick={() => setEdit(BASE_STEP)}><Add /> Add Step</Button></TableCell>   
                            <StyleledTablePagination
                                count={max}
                                page={page}
                                onPageChange={updatePage}
                                rowsPerPage={page_size}
                                onRowsPerPageChange={updateRowsPerPage}
                            />
                        </TableRow>
                        <TableRow>
                            {columns.map((column) => <TableCell key={column.key}>{column.name}</TableCell>)}
                            <TableCell>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {steps.map((step) => <TableRow key={step.id}>
                            {columns.map((column) => <TableCell key={`${step.id}-${column.name}`}>{column.transformer(step)}</TableCell>)}
                            <TableCell sx={{ cursor: 'pointer' }}><EditOutlined onClick={() => setEdit(step)} /></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledModal open={!Validator.isEmpty(edit)} onClose={() => setEdit(null)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '100%', overflow: 'scroll' }}>
                    <Box sx={{ flex: '1 0',  maxHeight: '100%', overflow: 'scroll' }}><TestStepEdit step={edit} onClose={() => setEdit(null)} /></Box>
                    <Button variant="contained" sx={{ width: '100%', marginTop: '10px' }} onClick={() => setEdit(null)}>Close</Button>
                </Box>
            </StyledModal>
        </React.Fragment>
    )
};