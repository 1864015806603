import { Add, EditOutlined, PlayArrow, RemoveRedEye } from "@mui/icons-material";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, getSelectUtilityClasses, TableBody, Box, Button } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_URL, BASE_SUITE } from "../config/Constant";
import { AutomationSuite, Column } from "../store/entity";
import { handleSnack } from "../store/snack";
import { StyledModal, useStylePagination } from "../store/style";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
import { TestSuiteEdit } from "./TestSuiteEdit";
export const TestSuites = (props: { [key: string]: any }) => {
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [page_size, setPageSize] = React.useState(10);
    const [max, setMax] = React.useState(0);
    const [suites, setSuites] = React.useState<AutomationSuite[]>([]);
    const StyleledTablePagination = useStylePagination();
    const dispatch = useDispatch();
    function updatePage(e: any, newPage: number) {
        setPage(newPage);
    };

    function updateRowsPerPage(event: any) {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columns: Column[] = [
        {
            name: 'Id',
            key: 'id',
            transformer: (suite: AutomationSuite) => suite.id
        },
        {
            name: 'Name',
            key: 'name',
            transformer: (suite: AutomationSuite) => suite.name
        },
        {
            name: 'Configs',
            key: 'configs',
            transformer: (suite: AutomationSuite) => suite.configs.length
        }
    ];

    function getSuites() {
        var url = API_URL + "/api/v1/suite";
        var params = {
            page: page,
            page_size: page_size
        }
        Http.get(url, params, (response: any) => {
            setSuites(response.data.suites);
            setMax(response.data.count);
        });
    };

    React.useEffect(() => {
        getSuites();
    }, [page, page_size]);

    const [edit, setEdit] = React.useState<{[key: string]: any} | null>(null);

    function trigger(suite: AutomationSuite) {
        var url = API_URL + "/api/v1/trigger/" + suite.id;        
        Http.get(url, {}, (response: any) => {
            handleSnack(dispatch, 'Suite Triggered');
        });
    };
    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell><Button onClick={() => setEdit(BASE_SUITE)}><Add /> Add Suite</Button></TableCell>   
                            <StyleledTablePagination
                                count={max}
                                page={page}
                                onPageChange={updatePage}
                                rowsPerPage={page_size}
                                onRowsPerPageChange={updateRowsPerPage}
                            />
                        </TableRow>
                        <TableRow>
                            {columns.map((column) => <TableCell key={column.key}>{column.name}</TableCell>)}
                            <TableCell>Edit</TableCell>
                            <TableCell>Launch</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {suites.map((suite) => <TableRow key={suite.id}>
                            {columns.map((column) => <TableCell key={`${suite.id}-${column.name}`}>{column.transformer(suite)}</TableCell>)}
                            <TableCell sx={{ cursor: 'pointer' }}><EditOutlined onClick={() => setEdit(suite)} /></TableCell>
                            <TableCell sx={{ cursor: 'pointer' }}><PlayArrow onClick={() => trigger(suite)} /></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledModal open={!Validator.isEmpty(edit)} onClose={() => setEdit(null)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '100%', overflow: 'scroll' }}>
                    <Box sx={{ flex: '1 0', maxHeight: '100%', overflow: 'scroll' }}><TestSuiteEdit suite={edit} onClose={() => setEdit(null)} /></Box>
                    <Button variant="contained" sx={{ width: '100%', marginTop: '10px' }} onClick={() => setEdit(null)}>Close</Button>
                </Box>
            </StyledModal>
        </React.Fragment>
    )
};