import { ReduxAction } from "./actions";
import { createReducer } from "./utils";
export interface DrawerState {
    open: boolean
}

const initialState = {
    open: false
};

export function handlerDrawler(dispatch: any, state: boolean) {
    dispatch({
        type: ReduxAction.DRAWER,
        payload: {
            open: state
        }
    })
};

export const drawerManager = createReducer(initialState, {
    [ReduxAction.DRAWER]: (state: any, action: any) => {
        return {
            ...state,
            ...action.payload
        };
    }
});