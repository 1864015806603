import { Validator } from "./Validator";

const Storage = () => {
    return {
        setItem: function(key: string, data: any) {
            localStorage.setItem(key, JSON.stringify(data));
        },
        getItem: function(key: string, defaultValue: any): any {
            var data = localStorage.getItem(key);
            if(Validator.isEmpty(data)) {
                return defaultValue
            }
            return JSON.parse(data!);
        },
        clear: function() {
            localStorage.clear();
        }
     }
};

const StorageHelper = Storage();
export default StorageHelper;