import { ReduxAction } from "./actions";
import { AutomationConfig } from "./entity";
import { createReducer } from "./utils";

let automations: AutomationConfig[] = []
const initialState: { [key: string]: AutomationConfig[] } = {
    automations: automations
}

export const automationManager = createReducer(initialState, {
    [ReduxAction.AUTOMATION_LIST]: (state: any, action: any) => {
        return {
            ...state,
            ...action.payload
        }
    }
});