import { Circle, EditOutlined, RemoveRedEye, Stop } from "@mui/icons-material";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, getSelectUtilityClasses, TableBody, Box, Button } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config/Constant";
import { AutomationBatch, AutomationStep, AutomationSuite, Column } from "../store/entity";
import { StyledModal, useStylePagination } from "../store/style";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
import { ExecutedConfig } from "./ExecutedConfig";
export const TestBatch = (props: { [key: string]: any }) => {
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [page_size, setPageSize] = React.useState(10);
    const [max, setMax] = React.useState(0);
    const [batches, setBatches] = React.useState<AutomationBatch[]>([]);
    const StyleledTablePagination = useStylePagination();

    function updatePage(e: any, newPage: number) {
        setPage(newPage);
    };

    function updateRowsPerPage(event: any) {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columns: Column[] = [
        {
            name: 'Id',
            key: 'id',
            transformer: (batch: AutomationBatch) => batch.id
        },
        {
            name: 'Created',
            key: 'created',
            transformer: (batch: AutomationBatch) => batch.created
        },
        {
            name: 'Suite',
            key: 'suite',
            transformer: (batch: AutomationBatch) => batch.suite.name
        },        
        {
            name: 'Test Configs',
            key: 'configs',
            transformer: (batch: AutomationBatch) => batch.suite.configs.length
        },          
        {
            name: 'Total Steps',
            key: 'configs',
            transformer: (batch: AutomationBatch) => batch.steps.length
        },
        {
            name: 'Status',
            key: 'status',
            transformer: (batch: AutomationBatch) => batch.status
        }
    ];

    function getBatch() {
        var url = API_URL + "/api/v1/batch";
        var params = {
            page: page,
            page_size: page_size
        }
        Http.get(url, params, (response: any) => {
            setBatches(response.data.batches);
            setMax(response.data.count);
        });
    };

    React.useEffect(() => {
        getBatch();
    }, [page, page_size]);

    const [current_batch, setCurrentBatch] = React.useState<AutomationBatch | null>(null);

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <StyleledTablePagination
                                count={max}
                                page={page}
                                onPageChange={updatePage}
                                rowsPerPage={page_size}
                                onRowsPerPageChange={updateRowsPerPage}
                            />
                        </TableRow>
                        <TableRow>
                            {columns.map((column) => <TableCell key={column.key}>{column.name}</TableCell>)}
                            <TableCell>Status</TableCell>
                            <TableCell>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {batches.map((batch) => <TableRow key={batch.id}>
                            {columns.map((column) => <TableCell key={`${batch.id}-${column.name}`}>{column.transformer(batch)}</TableCell>)}
                            <TableCell sx={{ cursor: 'pointer' }}>
                                {batch.status === 'PENDING' && <Stop sx={{ color: 'yellow' }} />}
                                {batch.status === 'COMPLETED_WITH_ERROR' && <Circle sx={{ color: 'red' }} />}
                                {batch.status === 'COMPLETED' && <Circle sx={{ color: 'green' }} />}
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }}><RemoveRedEye sx={{ color: 'green' }} onClick={() => setCurrentBatch(batch)} /></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledModal open={!Validator.isEmpty(current_batch)} onClose={() => setCurrentBatch(null)} sx={{ maxHeight: 500 }}>
                <Box>
                    <Box sx={{ maxHeight: 300, overflow: 'scroll' }}>
                        <ExecutedConfig batch={current_batch!}/>
                    </Box>
                    <Button sx={{ widht: '100%' }} onClick={() => setCurrentBatch(null)}>Close</Button>
                </Box>
            </StyledModal>
        </React.Fragment>
    )
};