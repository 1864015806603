import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './Header';
import MainDashboard from './MainDashboard';

const Landing = (props: {[key: string]: any}) => {
  return (
    <React.Fragment>
      <Header/>
      <Routes>
        <Route path="/app/*" element={<MainDashboard {...props}/>}/>  
        <Route path="*" element={<MainDashboard {...props}/>}/>        
      </Routes>
    </React.Fragment>
  );
}

export default Landing;
