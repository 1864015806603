import { ReduxAction } from "./actions";
import { createReducer } from "./utils";
export interface SnackState {
    message: string,
    duration: number,
    severity: 'success' | 'info' | 'warning' | 'error'
}

const initialState = {
    message: '',
    duration: 6000,
    severity: 'success'
};

export function handleSnack(dispatch: any, message: string = '', severity: string = 'success', duration: number = 6000) {    
    dispatch({
        type: ReduxAction.SNACK,
        payload: {
            message: message,
            duration: duration,
            severity: severity
        }
    })
};

export const snackManager = createReducer(initialState, {
    [ReduxAction.SNACK]: (state: any, action: any) => {
        return {
            ...state,
            ...action.payload
        };
    }
});