export const Validator = {
    isUnderfined: function(value: any): boolean {
        return typeof value === 'undefined';
    },
    isNull: function(value: any): boolean {
        return this.isUnderfined(value) || value == null;
    },
    isEmpty: function(value: any): boolean {
        return this.isNull(value) || value.length == 0;
    },
    isObj: function(value: any): boolean {
        return !this.isNull(value) && typeof value === 'object';
    }
}