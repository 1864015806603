import { Add, EditOutlined } from "@mui/icons-material";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, getSelectUtilityClasses, TableBody, Modal, Button } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config/Constant";
import { Column, XPath } from "../store/entity";
import { StyledModal, useStylePagination } from "../store/style";
import Http from "../utils/Http";
import { Validator } from "../utils/Validator";
import { XPathEdit } from "./XPathEdit";
export const XPaths = (props: { [key: string]: any }) => {
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [page_size, setPageSize] = React.useState(10);
    const [max, setMax] = React.useState(0);
    const [xPaths, setXPaths] = React.useState<XPath[]>([]);
    const StyleledTablePagination = useStylePagination();

    function updatePage(e: any, newPage: number) {
        setPage(newPage);
    };

    function updateRowsPerPage(event: any) {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columns: Column[] = [
        {
            name: 'Id',
            key: 'id',
            transformer: (xPath: XPath) => xPath.id
        },
        {
            name: 'Name',
            key: 'name',
            transformer: (xPath: XPath) => xPath.name
        },
        {
            name: 'Key',
            key: 'key',
            transformer: (xPath: XPath) => xPath.key
        },
        {
            name: 'Type',
            key: 'type',
            transformer: (xPath: XPath) => xPath.type
        },
        {
            name: 'XPath',
            key: 'xPath',
            transformer: (xPath: XPath) => xPath.xpath
        }
    ];

    function getSteps() {
        var url = API_URL + "/api/v1/xpath";
        var params = {
            page: page,
            page_size: page_size
        }
        Http.get(url, params, (response: any) => {
            setXPaths(response.data.xpaths);
            setMax(response.data.count);
        });
    };

    React.useEffect(() => {
        getSteps();
    }, [page, page_size]);

    const [edit, setEdit] = React.useState<{ [key: string]: any } | null>(null);

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell><Button onClick={() => setEdit({})}><Add /> Add XPath</Button></TableCell>
                            <StyleledTablePagination
                                count={max}
                                page={page}
                                onPageChange={updatePage}
                                rowsPerPage={page_size}
                                onRowsPerPageChange={updateRowsPerPage}
                            />
                        </TableRow>
                        <TableRow>
                            {columns.map((column) => <TableCell key={column.key}>{column.name}</TableCell>)}
                            <TableCell>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {xPaths.map((xPath) => <TableRow key={xPath.id}>
                            {columns.map((column) => <TableCell key={`${xPath.id}-${column.name}`}>{column.transformer(xPath)}</TableCell>)}
                            <TableCell sx={{ cursor: 'pointer' }}><EditOutlined onClick={() => setEdit(xPath)} /></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledModal open={!Validator.isEmpty(edit)} onClose={() => setEdit(null)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
                    <Box sx={{ flex: '1 0' }}><XPathEdit xPath={edit} onClose={() => setEdit(null)} /></Box>
                    <Button variant="contained" sx={{ width: '100%', marginTop: '10px' }} onClick={() => setEdit(null)}>Close</Button>
                </Box>
            </StyledModal>
        </React.Fragment>
    )
};