import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { TestConfigs } from "./TestConfigs";
import { TestSteps } from "./TestSteps";
import { TestBatch } from "./TestBatch";
import { TestSuites } from "./TestSuites";
import { XPaths } from "./XPaths";
const MainDashboard = (props: { [key: string]: any }) => {
    return (
        <Routes>
            <Route path="/suites" element={<TestSuites {...props} />} />
            <Route path="/configs" element={<TestConfigs {...props} />} />
            <Route path="/steps" element={<TestSteps {...props} />} />
            <Route path="/xpaths" element={<XPaths {...props} />} />
            <Route path="/batch" element={<TestBatch {...props} />} />
            <Route path="/report/{batch_id}" element={<MainDashboard {...props} />} />
        </Routes>
    );
};
export default MainDashboard;