import { combineReducers } from "redux";
import { automationManager } from "./automation";
import { drawerManager, DrawerState } from "./drawer";
import { AutomationConfig } from "./entity";
import { snackManager, SnackState } from "./snack";

const appReducer = combineReducers({
    automations: automationManager,
    drawer: drawerManager,
    snack: snackManager
});

export interface BaseState {
    automations: AutomationConfig[],
    drawer: DrawerState,
    snack: SnackState
}
export default appReducer;