import { Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, useTheme } from "@mui/material";
import * as React from "react";
import "../scss/base.scss";
import { useDispatch, useSelector } from "react-redux";
import { drawlerSelector } from "../store/selectors";
import { handlerDrawler } from "../store/drawer";
import { ChevronLeft, ChevronRight, Dashboard } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const drawerWidth = 240;
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));

export const SideDrawer = (props: { [key: string]: any }) => {
    const theme = useTheme();
    const drawer: boolean = useSelector(drawlerSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const items = [
        {
            name: 'Test Suite',
            path: '/app/suites',
            icon: <Dashboard />
        },
        {
            name: 'Test Config',
            path: '/app/configs',
            icon: <Dashboard />
        },
        {
            name: 'Test Step',
            path: '/app/steps',
            icon: <Dashboard />
        },
        {
            name: 'XPath',
            path: '/app/xpaths',
            icon: <Dashboard />
        },
        {
            name: 'Batch',
            path: '/app/batch',
            icon: <Dashboard />
        }
    ];

    function updateNavigation(path: string) {                
        navigate(path);
        handlerDrawler(dispatch, false);
    };
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    widht: drawerWidth,
                    boxSizing: 'border-box'
                },
            }}
            variant="persistent"
            anchor="left"
            open={drawer}>
            <DrawerHeader>
                <IconButton onClick={() => handlerDrawler(dispatch, !drawer)}>
                    {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {items.map((item) => (
                    <ListItem key={item.name} disablePadding onClick={() => updateNavigation(item.path)}>
                        <ListItemButton>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default Drawer;
