import * as React from "react";
import { AutomationBatch, AutomationConfig, BatchStep } from "../store/entity";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, getSelectUtilityClasses, TableBody, Box, Button } from "@mui/material";
import { Circle, RemoveRedEye, Stop } from "@mui/icons-material";
import { StyledModal } from "../store/style";
import { Validator } from "../utils/Validator";
interface ExecutedConfigInput {
    batch: AutomationBatch
}
interface Column {
    name: string,
    key: string,
    transformer: any
}
export const ExecutedConfig = ({ batch }: ExecutedConfigInput) => {
    const columns: Column[] = [
        {
            name: 'Id',
            key: 'id',
            transformer: (batch: AutomationBatch, config: AutomationConfig) => config.id
        },
        {
            name: 'Config',
            key: 'config_name',
            transformer: (batch: AutomationBatch, config: AutomationConfig) => config.name
        },
        {
            name: 'Steps',
            key: 'steps',
            transformer: (batch: AutomationBatch, config: AutomationConfig) => batch.steps.filter(s => s.config_id == config.id).length
        }
    ];

    function updateMessage(config: AutomationConfig) {
        var stepMessage = batch.steps.filter(s => s.config_id == config.id).map(s => s.message).join(", ");        
        setMessage(stepMessage);
    };
    const [message, setMessage] = React.useState<string | null>(null);
    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => <TableCell key={column.key}>{column.name}</TableCell>)}
                            <TableCell>Status</TableCell>
                            <TableCell>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {batch.suite.configs.map((config) => <TableRow key={config.id}>
                            {columns.map((column) => <TableCell key={`${config.id}-${column.name}`}>{column.transformer(batch, config)}</TableCell>)}
                            <TableCell sx={{ cursor: 'pointer' }}>
                                {batch.status === 'PENDING' && <Stop sx={{ color: 'yellow' }} />}
                                {batch.status === 'COMPLETED_WITH_ERROR' && <Circle sx={{ color: 'red' }} />}
                                {batch.status === 'COMPLETED' && <Circle sx={{ color: 'green' }} />}
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }}><RemoveRedEye sx={{ color: 'green' }} onClick={() => updateMessage(config)}/></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledModal open={!Validator.isEmpty(message)} onClose={() => setMessage(null)} sx={{ maxHeight: 500 }}>
                <Box>
                    <Box sx={{ maxHeight: 300, overflow: 'scroll' }}>
                        {message}
                    </Box>
                    <Button sx={{ widht: '100%' }} onClick={() => setMessage(null)}>Close</Button>
                </Box>
            </StyledModal>
        </React.Fragment>
    )
};