const HttpLoadService = () => {
    return {
        init: function(loader: boolean, loader_message: string) {

        },
        finish: function(loader: boolean) {

        }
    }
}
const Loader = HttpLoadService();
export default Loader;