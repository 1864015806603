import StorageHelper from "../utils/StorageHelper";
import { Validator } from "../utils/Validator";
import axios from "axios";
import Loader from "../utils/Loader";
import  { TOKEN_KEY } from '../config/Constant';

const HttpService = () => {
    return {
        getAuthHeader: function (token?: string) {
            if (Validator.isEmpty(token)) {
                token = StorageHelper.getItem(TOKEN_KEY, '');
            }
            return {
                Authorization: 'Bearer ' + token
            }
        },
        get: function (
            url: string,
            params: { [name: string]: any } = {},
            success_handler: ((arg: any) => any) | null = null,
            headers: { [name: string]: any } = {},
            error_handler: ((arg: any) => any) | null = null,
            loader: boolean = false,
            loader_message: string = ''
        ) {
            var cancelTokenSource = axios.CancelToken.source();
            headers = { ...headers, ...this.getAuthHeader()};
            const config = {
                params: params,
                cancelToken: cancelTokenSource.token,
                headers: headers
            };
            Loader.init(loader, loader_message);
            axios.get(url, config).then(res => {
                Loader.finish(loader);
                success_handler?.(res);
            }).catch(err => {
                Loader.finish(loader);
                error_handler?.(err);
            });
            return cancelTokenSource;
        },
        post: function (
            url: string,
            data: { [name: string]: any } = {},
            success_handler: ((arg: any) => any) | null = null,
            headers: { [name: string]: any } = {},
            error_handler: ((arg: any) => any) | null = null,
            loader: boolean = false,
            loader_message: string = '',
            baseConfig: {[name: string]: any} = {}
        ) {
            var cancelTokenSource = axios.CancelToken.source();
            headers = { ...headers, ...this.getAuthHeader()};
            var config = {
                cancelToken: cancelTokenSource.token,
                headers: headers
            };
            if(!Validator.isEmpty(baseConfig)) {
                config = {...baseConfig, ...config};
            }
            Loader.init(loader, loader_message);
            axios.post(url, data, config).then(res => {
                Loader.finish(loader);
                success_handler?.(res);
            }).catch(err => {
                Loader.finish(loader);
                error_handler?.(err);
            });
            return cancelTokenSource;
        },
        put: function (
            url: string,
            data: { [name: string]: any } = {},
            success_handler: ((arg: any) => any) | null = null,
            headers: { [name: string]: any } = {},
            error_handler: ((arg: any) => any) | null = null,
            loader: boolean = false,
            loader_message: string = '',
            baseConfig: {[name: string]: any} = {}
        ) {
            var cancelTokenSource = axios.CancelToken.source();
            headers = { ...headers, ...this.getAuthHeader()};
            var config = {
                cancelToken: cancelTokenSource.token,
                headers: headers
            };
            if(!Validator.isEmpty(baseConfig)) {
                config = {...baseConfig, ...config};
            }
            Loader.init(loader, loader_message);
            axios.put(url, data, config).then(res => {
                Loader.finish(loader);
                success_handler?.(res);
            }).catch(err => {
                Loader.finish(loader);
                error_handler?.(err);
            });
            return cancelTokenSource;
        },
        delete: function (
            url: string,
            data: { [name: string]: any } = {},
            success_handler: ((arg: any) => any) | null = null,
            headers: { [name: string]: any } = {},
            error_handler: ((arg: any) => any) | null = null,
            loader: boolean = false,
            loader_message: string = '',
            baseConfig: {[name: string]: any} = {}
        ) {
            var cancelTokenSource = axios.CancelToken.source();
            headers = { ...headers, ...this.getAuthHeader()};
            var config = {
                cancelToken: cancelTokenSource.token,
                headers: headers
            };
            if(!Validator.isEmpty(baseConfig)) {
                config = {...baseConfig, ...config};
            }
            Loader.init(loader, loader_message);
            axios.delete(url, data).then(res => {
                Loader.finish(loader);
                success_handler?.(res);
            }).catch(err => {
                Loader.finish(loader);
                error_handler?.(err);
            });
            return cancelTokenSource;
        }
    }
};

const Http = HttpService();
export default Http;