export const TOKEN_KEY = 'TOKEN_KEY';
export const API_URL = 'https://api-automation.thelevel.ai';
export const SUPPORTED_OPERATORS = [
    'FORM_SUBMIT', 'NAVIGATE', 'VALIDATION', 'PARENT'
];

export const BASE_STEP = {
    xpaths: [],
    params: {},
    internal_steps: []
};

export const BASE_CONFIG = {    
    params: {},
    steps: []
};

export const BASE_SUITE = {    
    configs: []
};